export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(docs)": [5,[2]],
		"/.examples/043f68ea4e55b829358ab43ee5f7c7ec2c538782fc3056c942d1bdb6ecf51073": [71,[4]],
		"/.examples/04ea1a9796a181c84b72b07abbb87afbc4623c2d024637ec704b801c6d3bee07": [72,[4]],
		"/.examples/0d1d74a18a91afccd1ba098d92ecc78bbd03feabad86304d8959eb998a526167": [73,[4]],
		"/.examples/174ff852ecdbdd27ab1d33a60f57798bd1c7a6916e4c5be24460e65ad318f492": [74,[4]],
		"/.examples/21169e8982193781f0d1100d5bc8d2df2e270e179d50dfc256e638d3c0293fd4": [75,[4]],
		"/.examples/21404fdbdad9f35f57e1db2cbbccec741ae162b692c4b6e0fec73f0a20bfad20": [76,[4]],
		"/.examples/268be543229bca89056a043fa1714ca96141c3919a959a8102fedecd05b981bc": [77,[4]],
		"/.examples/28b78b24fe44ea35543bbff3236f449543eb07260593357c49546c98f0255d59": [78,[4]],
		"/.examples/44fad8cb7fb3f13de2347c09250016d88ed8ce47d7b4e999b99848d924eb6201": [79,[4]],
		"/.examples/5f6232f8b9834b49cbbc2bfe48b6cb05ab2e550906624cfdbd3f5084d9294461": [80,[4]],
		"/.examples/601f899f43c9431843cfebd63deed2cb94cd313b7f445633886096bb63c774fe": [81,[4]],
		"/.examples/625a6e069eb869ebee45d6e657bc8912d1d9040398ab7408e20619d35dd2078e": [82,[4]],
		"/.examples/6627e94d7297abe5102b5854f6db6ff694deec4812220fc745900af9b11cf274": [83,[4]],
		"/.examples/66b23265d8b2d24876b9a83e8b1e87f3a475ea29eed51e84e83fa8058895f067": [84,[4]],
		"/.examples/6821d4b2ef255a1272aac38fe561c20d1f01c0264979f66218206c839c2718fb": [85,[4]],
		"/.examples/6e177d824ae881b3a730f0ff9ac871497071962c7114dc38dcb3c67ee98fe040": [86,[4]],
		"/.examples/742c2e90db69c4631458b7450707ed8f9fc59f3d291f6b45a2bf0797509b6e5c": [87,[4]],
		"/.examples/7b65728d81bb3a843ad0734b3b3da162a3e24314892f44dc81cfddcdc80f51fb": [88,[4]],
		"/.examples/7c733ca48ad143d89b9e2f6ffc7e3f66a55cfa30935cf60b48593834196441a7": [89,[4]],
		"/.examples/814dc15837152be0a01e4b4e9406af2a57013d5df0ffd41806dfd3ee8a2f83ea": [90,[4]],
		"/.examples/840bbee32e9ef8e915f8abfb616ee38077631d7fef5f2047686f4ffee2b31bdb": [91,[4]],
		"/.examples/9099d2f5e3f43bb0040f05da875c798a8024a29133c85bb2cf78e41ccf695f12": [92,[4]],
		"/.examples/90fb07ec5d0542bdcbbdce2a5a4c19a32cf14bb0325276bea7a0469914d8e3d8": [93,[4]],
		"/.examples/987bd21911afa466dae7343f9f1fa59a3bc0205295bc53e2873ac91f97c40c23": [94,[4]],
		"/.examples/ab2291f1df44ff1f31876b35a47bdf7f73d72a970ddef46f1a8a984c54e5004e": [95,[4]],
		"/.examples/ae408a006716a8cd8093b802975264cd3a544c59fabc0602cc2c0823c0ffd2a6": [96,[4]],
		"/.examples/bc11a2086f51b87e245f2113652b017f51f591d172845ecdfc8f6d73af035c3f": [97,[4]],
		"/.examples/be9527d36c6e8c9dd411ed45d70ae3a40b8cfaf6e6cf894b82b4047aacb1ff1f": [98,[4]],
		"/.examples/c9bb1a79c8c57b6f64e802df1a99c7310e06c865b39e4acd450790bfff69a027": [99,[4]],
		"/.examples/da552deeeeca6ba32245cb9dca5b1107a079f936a7164e9690f4e4199d28f672": [100,[4]],
		"/.examples/e0a2c85b9d1397647842811bad1977b767725235c76760a5b04a52db930ea88b": [101,[4]],
		"/.examples/e70b85cc3e2dd0f60ddc7e91ddae58767da2c72eeacf5f08ad37463db4fc612a": [102,[4]],
		"/.examples/ea9e5481ad9810d05ae0c4f7ec76b4e36963b109ce50e5eae686037b31d48888": [103,[4]],
		"/.examples/fb3524b2687172e681b21c33565ce6eeb03edd597b058cf23c353de9b6472cd9": [104,[4]],
		"/.examples/ff79704aec1b8b89f37825ca3d6847969420b379ff338f22b7a38c3cdf3eb5ed": [105,[4]],
		"/(docs)/components": [6,[2,3]],
		"/(docs)/components/AppTile": [7,[2,3]],
		"/(docs)/components/AppTile/code": [8,[2,3]],
		"/(docs)/components/AppTile/design": [9,[2,3]],
		"/(docs)/components/Breadcrumbs": [10,[2,3]],
		"/(docs)/components/Breadcrumbs/code": [11,[2,3]],
		"/(docs)/components/Breadcrumbs/design": [12,[2,3]],
		"/(docs)/components/ButtonGroup": [16,[2,3]],
		"/(docs)/components/ButtonGroup/code": [17,[2,3]],
		"/(docs)/components/ButtonGroup/design": [18,[2,3]],
		"/(docs)/components/Button": [13,[2,3]],
		"/(docs)/components/Button/code": [14,[2,3]],
		"/(docs)/components/Button/design": [15,[2,3]],
		"/(docs)/components/Calendar": [19,[2,3]],
		"/(docs)/components/Calendar/code": [20,[2,3]],
		"/(docs)/components/Calendar/design": [21,[2,3]],
		"/(docs)/components/CheckboxGroup": [25,[2,3]],
		"/(docs)/components/CheckboxGroup/code": [26,[2,3]],
		"/(docs)/components/CheckboxGroup/design": [27,[2,3]],
		"/(docs)/components/Checkbox": [22,[2,3]],
		"/(docs)/components/Checkbox/code": [23,[2,3]],
		"/(docs)/components/Checkbox/design": [24,[2,3]],
		"/(docs)/components/Combobox": [28,[2,3]],
		"/(docs)/components/Combobox/code": [29,[2,3]],
		"/(docs)/components/Combobox/design": [30,[2,3]],
		"/(docs)/components/DateInput": [31,[2,3]],
		"/(docs)/components/DateInput/code": [32,[2,3]],
		"/(docs)/components/DateInput/design": [33,[2,3]],
		"/(docs)/components/HeaderBar": [34,[2,3]],
		"/(docs)/components/HeaderBar/code": [35,[2,3]],
		"/(docs)/components/HeaderBar/design": [36,[2,3]],
		"/(docs)/components/InlineSelect": [37,[2,3]],
		"/(docs)/components/InlineSelect/code": [38,[2,3]],
		"/(docs)/components/InlineSelect/design": [39,[2,3]],
		"/(docs)/components/Listbox": [40,[2,3]],
		"/(docs)/components/Listbox/code": [41,[2,3]],
		"/(docs)/components/Listbox/design": [42,[2,3]],
		"/(docs)/components/Pagination": [43,[2,3]],
		"/(docs)/components/Pagination/code": [44,[2,3]],
		"/(docs)/components/Pagination/design": [45,[2,3]],
		"/(docs)/components/RadioGroup": [46,[2,3]],
		"/(docs)/components/RadioGroup/code": [47,[2,3]],
		"/(docs)/components/RadioGroup/design": [48,[2,3]],
		"/(docs)/components/Select": [49,[2,3]],
		"/(docs)/components/Select/code": [50,[2,3]],
		"/(docs)/components/Select/design": [51,[2,3]],
		"/(docs)/components/Switch": [52,[2,3]],
		"/(docs)/components/Switch/code": [53,[2,3]],
		"/(docs)/components/Switch/design": [54,[2,3]],
		"/(docs)/components/TextInput": [55,[2,3]],
		"/(docs)/components/TextInput/code": [56,[2,3]],
		"/(docs)/components/TextInput/design": [57,[2,3]],
		"/(docs)/components/ToggleButton": [58,[2,3]],
		"/(docs)/components/ToggleButton/code": [59,[2,3]],
		"/(docs)/components/ToggleButton/design": [60,[2,3]],
		"/(docs)/foundations": [61,[2]],
		"/(docs)/foundations/colour": [62,[2]],
		"/(docs)/foundations/corners": [63,[2]],
		"/(docs)/foundations/elevation": [64,[2]],
		"/(docs)/foundations/grid": [65,[2]],
		"/(docs)/foundations/icons": [66,[2]],
		"/(docs)/foundations/intro": [67,[2]],
		"/(docs)/foundations/spacing": [68,[2]],
		"/(docs)/foundations/typography": [69,[2]],
		"/(docs)/patterns": [70,[2]],
		"/sandbox": [106],
		"/sandbox/Breadcrumbs": [107],
		"/sandbox/Button": [108],
		"/sandbox/Checkbox": [109],
		"/sandbox/Combobox": [110],
		"/sandbox/DateInput": [111],
		"/sandbox/InlineSelect": [112],
		"/sandbox/Listbox": [113],
		"/sandbox/MenuButton": [114],
		"/sandbox/Modal": [115],
		"/sandbox/Popup": [116],
		"/sandbox/Select": [117],
		"/sandbox/Switch": [118],
		"/sandbox/TextInput": [119],
		"/sandbox/Tooltip": [120],
		"/sandbox/TreeView": [121],
		"/sandbox/roadmap": [122]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';